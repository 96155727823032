import { Input as NBInput } from '@chakra-ui/react'
import { InputProps } from '@heytutor/common'
import { useField, useFormikContext } from 'formik'
import { ChangeEvent, FocusEvent, forwardRef } from 'react'
import FieldWrapper from './field-wrapper'

type Props = InputProps & any

const Input = forwardRef(
  ({ label, name, isRequired, helper, onChange = undefined, onBlur = undefined, ...rest }: Props, ref: any) => {
    const [field] = useField(name)
    const { handleChange, handleBlur, setFieldTouched } = useFormikContext()

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
      // @ts-ignore
      handleChange(name)(event)

      onChange && onChange(event)
    }

    const handleInputBlur = (event: FocusEvent<HTMLInputElement>) => {
      setFieldTouched(name)
      handleBlur(name)

      onBlur && onBlur(event)
    }

    return (
      <FieldWrapper label={label} name={name} isRequired={isRequired} helper={helper}>
        <NBInput {...rest} ref={ref} value={field.value} onChange={handleInputChange} onBlur={handleInputBlur} />
      </FieldWrapper>
    )
  }
)

Input.displayName = 'Input'
export default Input
