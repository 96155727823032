import { List, ListItem } from '@chakra-ui/react'
import { useFormikContext } from 'formik'
import { ChangeEvent, ComponentProps, KeyboardEvent, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import Input from './input'

interface TypeaheadInputProps extends ComponentProps<typeof Input> {
  options?: string[]
}

const TypeaheadInput = ({ options = [], name, ...props }: TypeaheadInputProps) => {
  const [searchText, setSearchText] = useState<string>('')
  const [filteredOptions, setFilteredOptions] = useState<Array<{ value: string; label: string }>>([])
  const [selectedOptionIndex, setSelectedOptionIndex] = useState<number>(0)
  const [hasFocus, setHasFocus] = useState<boolean>(false)
  const { setFieldValue } = useFormikContext()
  const inputRef = useRef<HTMLInputElement>(null)

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target?.value ?? '')
  }

  useEffect(() => {
    if (searchText) {
      const filtered = options
        .filter((option) => option.toLowerCase().includes(searchText.toLowerCase()))
        .map((option) => {
          const regex = new RegExp(`(${searchText})`, 'gi')
          const highlighted = option.replace(regex, '<b>$1</b>')

          return { value: option, label: highlighted }
        })

      setFilteredOptions(filtered)
    } else {
      setFilteredOptions([])
    }
  }, [searchText, options])

  useEffect(() => {
    setSelectedOptionIndex(filteredOptions.length > 0 ? 0 : -1)
  }, [filteredOptions, setSelectedOptionIndex])

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'ArrowDown') {
      event.preventDefault()
      setSelectedOptionIndex((prevIndex) => Math.min(prevIndex + 1, filteredOptions.length - 1))
    } else if (event.key === 'ArrowUp') {
      event.preventDefault()
      setSelectedOptionIndex((prevIndex) => Math.max(prevIndex - 1, 0))
    } else if (event.key === 'Enter' && selectedOptionIndex >= 0) {
      event.preventDefault()
      const selectedOption = filteredOptions[selectedOptionIndex]

      if (selectedOption) {
        setFieldValue(name, selectedOption.value)
        setFilteredOptions([])
        setSelectedOptionIndex(-1)
      }
    }
  }

  const inputRect = inputRef.current?.getBoundingClientRect()
  const optionsStyle = {
    top: inputRect ? inputRect.bottom : 0,
    left: inputRect ? inputRect.left : 0,
    width: inputRect ? `${inputRect.width}px` : 'auto'
  }

  return (
    <div>
      <Input
        name={name}
        onChange={handleInputChange}
        onFocus={() => setHasFocus(true)}
        onBlur={() => setHasFocus(false)}
        onKeyDown={handleKeyDown}
        ref={inputRef}
        {...props}
      />
      {hasFocus &&
        filteredOptions.length > 0 &&
        ReactDOM.createPortal(
          <List
            spacing={1}
            zIndex={1}
            position="absolute"
            padding="5px"
            border="1px solid #ddd"
            background="white"
            maxHeight="200px"
            overflow="auto"
            boxShadow="0px 1px 3px -1px rgba(0,0,0,0.75)"
            style={optionsStyle}
          >
            {filteredOptions.map(({ value, label }, index) => (
              <ListItem
                key={index}
                cursor="pointer"
                padding="5px"
                borderRadius="2px"
                lineHeight="20px"
                backgroundColor={selectedOptionIndex === index ? '#eee' : 'white'}
                _hover={{
                  backgroundColor: '#eee'
                }}
                onMouseDown={(e) => {
                  e.preventDefault()
                  setFieldValue(name, value)
                  setFilteredOptions([])
                }}
                dangerouslySetInnerHTML={{ __html: label }}
              />
            ))}
          </List>,
          document.body
        )}
    </div>
  )
}

export default TypeaheadInput
