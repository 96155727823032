import { executeQuery } from '@heytutor/common'
import { GetLessonCategoriesDocument } from 'graphql/generated'
import { ComponentProps, useCallback, useEffect, useState } from 'react'
import TypeaheadInput from './typeahead-input'

const CategoriesInput = (props: ComponentProps<typeof TypeaheadInput>) => {
  const [categories, setCategories] = useState<Array<string>>([])
  const updateCategories = useCallback(async () => {
    const { lessonCategories: categories } = await executeQuery(GetLessonCategoriesDocument)

    setCategories(categories)
  }, [])

  useEffect(() => {
    updateCategories()
  }, [updateCategories])

  return <TypeaheadInput {...props} options={categories} />
}

export default CategoriesInput
